import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter } from "react-router-dom";
import ListRouters from "./routes";
import 'antd/dist/antd.css';
import '../src/stylesheets/main.scss'
import {persistStore} from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import configStore from "./store";
const store = configStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistStore(store)}>
            <BrowserRouter>
              <ListRouters />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

